function addOrReplace(arr, newObj, identKey = "id") {
	if (arr && arr.length > 0) {
		return [
			...arr.filter((obj) => obj[identKey] !== newObj[identKey]),
			{
				...arr.find((obj) => obj[identKey] === newObj[identKey]),
				...newObj,
			},
		];
	}

	return [newObj];
}

function addOrReplaceWithOrder(arr, newObj, identKey = "id") {
	if (arr && arr.length > 0) {
		return [
			...arr.filter((obj) => obj[identKey] !== newObj[identKey]),
			{
				...arr.find((obj) => obj[identKey] === newObj[identKey]),
				...newObj,
			},
		].sort((a, b) => a?.order - b?.order);
	}

	return [newObj];
}

export const shuffle = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

export { addOrReplace, addOrReplaceWithOrder };
